import React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <main>
      <h1>Нет такой страницы</h1>
      <Link to={'/'}>Вернуться на главную</Link>
    </main>
  )
}

export default NotFoundPage
